.templateList .customBtn:nth-child(2) {
  background-color: #5C6BE8;
  border: none;
}
.templateList .customBtn:nth-child(2):hover {
  background-color: #6875ee;
}
.operationControl .itemLabel {
  min-width: 6rem;
}
.docsDialog .el-form-item {
  margin-bottom: 20px;
}
